<template>
  <Layout class="GameLibrary">
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <!-- clearable -->
        <el-form-item
          label="游戏名"
          prop="game_name"
        >
          <el-input
            v-model="formData.game_name"
            clearable
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item
          label="游戏ID"
          prop="game_id"
        >
          <el-input
            v-model="formData.game_id"
            placeholder="请输入"
            clearable
          />
        </el-form-item>
        <div>
          <el-form-item label="置顶状态">
            <el-select
              v-model="formData.top"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in topType"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="启动状态">
            <el-select
              v-model="formData.status"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in statusType"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleSearch"
            >
              搜索
            </el-button>
          </el-form-item>
        </div>
      </el-form>
      <template v-slot:right>
        <el-form
          ref="form"
          :inline="true"
          :model="formData"
        >
          <el-form-item prop="game_ids">
            <el-input
              v-model="game_ids"
              clearable
              style="width: 370px;"
              placeholder="输入游戏ID（多个用“,”分隔）后按右方按钮添加"
            />
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleAdd"
            >
              添加游戏
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-tooltip
              placement="bottom"
              effect="light"
              class="tooltip"
            >
              <div slot="content">
                1.推荐列表默认显示15条游戏内容，<br>&emsp;根据优先“置顶”游戏->“非置顶”游戏的优先级对用户显示，<br>&emsp;置顶状态可以视为必定显示游戏，<br>&emsp;非置顶可视为随机显示游戏。<br>2.
                置顶游戏配置超过10条时，仅显示前10条内容。<br>
              </div>
              <i
                style="font-size: 22px;"
                class="el-icon-warning-outline"
              />
            </el-tooltip>
          </el-form-item>
        </el-form>
      </template>
    </BHeader>
    <el-table
      v-loading="listInfo.loading"
      :data="listInfo.list"
      stripe
      style="width: 100%"
    >
      <el-table-column
        label="排序"
        prop="rank"
      />
      <el-table-column
        label="游戏ID"
        prop="game_id"
      />
      <el-table-column
        label="游戏名称"
        prop="game_name"
      >
        <template slot-scope="scope">
          {{ scope.row.docs[0].game_name }}
        </template>
      </el-table-column>
      <el-table-column
        label="封面图"
        prop="updateTime"
      >
        <template slot-scope="scope">
          <el-image
            class="game-cover"
            :src="scope.row.docs[0].header_image_url"
            fit="cover"
            style="width:150px;height:100px"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="置顶状态"
        prop="imageNum"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.top"
            :active-value="1"
            :inactive-value="0"
            style="margin-left: 10px; margin-bottom: 10px"
            @change="handleOff(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <div class="controls">
            <el-switch
              v-model="scope.row.status"
              :active-value="1"
              :inactive-value="0"
              active-text="开启"
              inactive-text="禁用"
              style="margin-left: 10px; margin-bottom: 10px"
              @change="handleOff(scope.row)"
            />
            <el-button
              class="rank"
              @click="handleRank(scope.row)"
            >
              排序设置
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="修改排序"
      :visible.sync="dialogVisible"
      width="1000px"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :inline="true"
        :model="formRank"
      >
        <!-- clearable -->
        <el-form-item
          label="排序"
          prop="rank"
        >
          <el-input
            v-model="formRank.rank"
            clearable
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSubmint"
          >
            保存
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <Pagination
      :total="listInfo.total"
      :page.sync="formData.page"
      :limit.sync="formData.pageSize"
      @pagination="getList"
    />
  </Layout>
</template>

<script>
import {
  commonRequestGet,
  commonRequestPost,
  commonRequestPut
} from '@/api/commonApi.js'
import { gameStartModeList } from '@/utils/selectOptions'
const topType = [
  { value: 0, name: '取消置顶' },
  { value: 1, name: '置顶' }
]
const statusType = [
  { value: 0, name: '禁用' },
  { value: 1, name: '启用' }
]
export default {
  name: 'GameRecommendation',
  data () {
    return {
      gameStartModeList,
      statusType,
      topType,
      formData: {
        page: 1,
        pageSize: 10,
        game_id: null,
        game_name: null,
        top: null,
        status: null
      },
      listInfo: {
        list: [],
        total: 0,
        loading: false
      },
      formRank: {
        rank: 0,
        id: ''
      },
      game_ids: '',
      dialogVisible: false,
      enabledGamesCount: 0
    }
  },
  created () {
    this.getList()
    this.getListTop()
  },
  methods: {
    async getList () {
      try {
        const res = await commonRequestGet('admin/featured-games', this.formData)
        this.listInfo.list = res.data || []
        this.listInfo.total = res.total
      } catch (err) {
        console.log(err)
        this.listInfo.loading = false
      }
    },
    async getListTop () {
      try {
        const res = await commonRequestGet('admin/featured-games')
        this.enabledGamesCount = res.data.filter((game) => game.top === 1).length
        console.log(this.enabledGamesCount, 'this.enabledGamesCount')

        if (this.enabledGamesCount > 10) {
          this.$message.error('置顶游戏数量已达最大')
        }
      } catch (err) {
        console.log(err)
        this.listInfo.loading = false
      }
    },

    handleSearch () {
      this.getList()
      this.getListTop()
    },
    async handleAdd () {
      try {
        await commonRequestPost(
          'admin/featured-games',
          { game_ids: this.game_ids }
        )
        this.$message({
          message: '已添加成功',
          type: 'success'
        })
        this.getList()
      } catch (err) {
        this.$message.error(err.response.data.message)
        this.getList()
      }
    },
    handleRank (row) {
      this.dialogVisible = true
      this.formRank.id = row.id
    },
    handleClose () {
      this.formRank.rank = 0
      this.dialogVisible = false
    },
    async handleSubmint () {
      await commonRequestPut(
        `admin/featured-games/${this.formRank.id}`,
        this.formRank
      )
      this.dialogVisible = false
      this.formRank.rank = 0
      this.getList()
    },
    async handleOff (val) {
      const str = val.id.toString()
      const param = {
        top: val.top,
        status: val.status
      }
      try {
        await commonRequestPut(`admin/featured-games/${str}`, param)
        this.getListTop()
      } catch (err) {
        this.$message.error(err.response.data.message)
        // this.getList()
      }
    }
  }
}
</script>

  <style scoped lang="less">
    .GameLibrary {
      .overview {
        display: flex;
        // padding: 0 0 20px 0;
        min-width: 880px;
        margin-bottom: 10px;
        .items {
          width: 210px;
          height: 100px;
          box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
          border-radius: 4px;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .num {
            font-size: 26px;
            margin-bottom: 10px;
            .unit {
              font-size: 14px;
            }
          }
          .name {
            font-size: 12px;
          }
        }
      }
    }
    .controls {
    display: flex;
    margin-top: 14px;
    flex-direction: row;
    .rank{
      margin-left: 50px;
      margin-top: -14px;
    }
  }
  </style>
